<!--
  作者：顾先生
-->
<template>
  <div class="tabColumn-container">
    <p>
      <slot></slot>
    </p>
    <img src="../../assets/images/6.png">
  </div>
</template>

<script>
export default {
  name: 'TabColumn'
}
</script>

<style scoped lang="less">
.tabColumn-container{
  position: relative;
  margin-top: 37px;
  width: 200px;
  height:25px;
  border-left: 5px solid #bb0200;
  img{
    padding-top:10px;
  }
  p{
    position: absolute;
    left:8px;
    top:-10px;
    font-size:24px;
    color: #AC2317;
  }
}
</style>
