<template>
  <div>
    <div class="main-box">
      <div :class="['container', 'container-register', { 'is-txl': isLogin }]">
        <form>
          <h2 class="title">创建账户</h2>
          <span class="text">使用企业名称、信用代码、手机号进行注册</span>
          <input class="form__input" v-model="name" type="text" placeholder="企业名称" />
          <input class="form__input" v-model="email" type="text" placeholder="统一社会信用代码" />
          <input class="form__input" v-model="password" type="text" placeholder="手机号" />
          <input class="form__input" v-model="password1" type="password" placeholder="密码" />
          <input class="form__input" v-model="password2" type="password" placeholder="确认密码" />
          <div class="primary-btn" @click="ZhuCe">立即注册</div>
        </form>
      </div>
      <div
        :class="['container', 'container-login', { 'is-txl is-z200': isLogin }]"
      >
        <form>
          <h2 class="title">守信企业计划</h2>
          <span class="text">欢迎来到守信企业计划登录平台！</span>
          <input class="form__input" v-model="PhoneLogin" type="text" placeholder="请输入手机号" />
          <input class="form__input" v-model="PasswordLogin" type="password" placeholder="请输入密码" />
          <div class="primary-btn" @click="DengLu">立即登录</div>
        </form>
      </div>
      <div :class="['switch', { login: isLogin }]">
        <div class="switch__circle"></div>
        <div class="switch__circle switch__circle_top"></div>
        <div class="switch__container">
          <h2>{{ isLogin ? '你好、朋友！' : '欢迎回来！' }}</h2>
          <p>
            {{
              isLogin
                ? '输入您的个人详细信息并与我们一起了解各行各业的企业文化'
                : '要与我们保持联系，请使用您的个人信息登录'
            }}
          </p>
          <div class="primary-btn" @click="isLogin = !isLogin">
            {{ isLogin ? '立即注册' : '立即登录' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'LoginBox',
  data() {
    return {
      isLogin: false,
      loginForm: {
        email: '',
        password: ''
      },
      PhoneLogin: '',
      PasswordLogin: '',
      name: '',
      email: '',
      password: '',
      password1: '',
      password2: ''
    }
  },
  methods: {
    openFullScreen2() {
      const loading = this.$loading({
        lock: true,
        text: '注册中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      setTimeout(() => {
        loading.close()
        this.$message({
          message: '恭喜你，注册成功！欢迎加入守信企业计划平台',
          type: 'success'
        })
        this.name = ''
        this.email = ''
        this.password = ''
        this.password1 = ''
        this.password2 = ''
        this.isLogin = true
      }, 1000)
    },
    openFullScreen3(msg) {
      const loading = this.$loading({
        lock: true,
        text: '登录中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      setTimeout(() => {
        this.$router.push({ path: '/', query: { msg: msg } })
        loading.close()
        this.isLogin = true
      }, 1000)
    },
    ZhuCe() {
      if (this.name.trim() === '') {
        this.$notify.error({
          title: '错误',
          message: '企业名称不能为空！！！'
        })
        return
      }
      if (this.email.trim() === '') {
        this.$notify.error({
          title: '错误',
          message: '统一信用社会代码不能为空！！！'
        })
        return
      }
      if (this.email.length !== 18) {
        this.$notify.error({
          title: '错误',
          message: '统一信用社会代码为18位！！！'
        })
        return
      }
      if (this.password.trim() === '') {
        this.$notify.error({
          title: '错误',
          message: '手机号不能为空！！！'
        })
        return
      }
      if (this.password.length !== 11) {
        this.$notify.error({
          title: '错误',
          message: '手机号必须为11位！！！'
        })
        return
      }
      if (this.password1.trim() === '' || this.password2.trim() === '') {
        this.$notify.error({
          title: '错误',
          message: '密码不能为空！！！'
        })
        return
      }
      // eslint-disable-next-line no-self-compare
      if (this.password1 !== this.password2) {
        this.$notify.error({
          title: '错误',
          message: '两次密码输入不一致！！！'
        })
        return
      }
      axios({
        // 请求方式get或post
        method: 'get',
        // 请求路径
        url: 'https://api.zhongqixin360.com/index.php/Plaque/register',
        // 请求参数
        params: {
          company: this.name,
          phone: this.password,
          code: this.email,
          pwd: this.password1
        }
      }).then(res => {
        // 成功回调
        console.log(res.data.msg)
        if (res.data.code === 1) {
          this.$notify.error({
            title: '注册失败',
            message: res.data.msg
          })
        } else {
          this.openFullScreen2()
        }
      })
    },
    DengLu() {
      if (this.PhoneLogin.trim() === '') {
        this.$notify.error({
          title: '错误',
          message: '手机号不能为空！！！'
        })
        return
      }
      if (this.PhoneLogin.length < 11) {
        this.$notify.error({
          title: '错误',
          message: '手机号必须输入11位！！！'
        })
        return
      }
      if (this.PasswordLogin.trim() === '') {
        this.$notify.error({
          title: '错误',
          message: '密码不能为空！！！'
        })
      }
      axios({
        // 请求方式get或post
        method: 'get',
        // 请求路径
        url: 'https://api.zhongqixin360.com/index.php/Plaque/login',
        // 请求参数
        params: {
          phone: this.PhoneLogin,
          pwd: this.PasswordLogin
        }
      }).then(res => {
        // 成功回调
        if (res.data.code === 1) {
          this.$notify.error({
            title: '登录失败！',
            message: '账号或密码错误！！！'
          })
          return
        }
        this.openFullScreen3(res.data.data)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.main-box {
  position: relative;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 1000px;
  min-width: 1000px;
  min-height: 600px;
  height: 600px;
  padding: 25px;
  background-color: #ecf0f3;
  box-shadow: 10px 10px 10px #d1d9e6, -10px -10px 10px #f9f9f9;
  border-radius: 12px;
  overflow: hidden;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 600px;
    height: 100%;
    padding: 25px;
    background-color: #ecf0f3;
    transition: all 1.25s;
    form {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      color: #a0a5a8;
      .title {
        font-size: 34px;
        font-weight: 700;
        line-height: 3;
        color: #181818;
      }
      .text {
        margin-top: 30px;
        margin-bottom: 12px;
      }
      .form__input {
        width: 350px;
        height: 40px;
        margin: 4px 0;
        padding-left: 25px;
        font-size: 13px;
        letter-spacing: 0.15px;
        border: none;
        outline: none;
        // font-family: 'Montserrat', sans-serif;
        background-color: #ecf0f3;
        transition: 0.25s ease;
        border-radius: 8px;
        box-shadow: inset 2px 2px 4px #d1d9e6, inset -2px -2px 4px #f9f9f9;
        &::placeholder {
          color: #a0a5a8;
        }
      }
    }
  }
  .container-register {
    z-index: 100;
    left: calc(100% - 600px);
  }
  .container-login {
    left: calc(100% - 600px);
    z-index: 0;
  }
  .is-txl {
    left: 0;
    transition: 1.25s;
    transform-origin: right;
  }
  .is-z200 {
    z-index: 200;
    transition: 1.25s;
  }
  .switch {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 400px;
    padding: 50px;
    z-index: 200;
    transition: 1.25s;
    background-color: #ecf0f3;
    overflow: hidden;
    box-shadow: 4px 4px 10px #d1d9e6, -4px -4px 10px #f9f9f9;
    color: #a0a5a8;
    .switch__circle {
      position: absolute;
      width: 500px;
      height: 500px;
      border-radius: 50%;
      background-color: #ecf0f3;
      box-shadow: inset 8px 8px 12px #d1d9e6, inset -8px -8px 12px #f9f9f9;
      bottom: -60%;
      left: -60%;
      transition: 1.25s;
    }
    .switch__circle_top {
      top: -30%;
      left: 60%;
      width: 300px;
      height: 300px;
    }
    .switch__container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      width: 400px;
      padding: 50px 55px;
      transition: 1.25s;
      h2 {
        font-size: 34px;
        font-weight: 700;
        line-height: 3;
        color: #181818;
      }
      p {
        font-size: 14px;
        letter-spacing: 0.25px;
        text-align: center;
        line-height: 1.6;
      }
    }
  }
  .login {
    left: calc(100% - 400px);
    .switch__circle {
      left: 0;
    }
  }
  .primary-btn {
    width: 180px;
    height: 50px;
    border-radius: 25px;
    margin-top: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 14px;
    letter-spacing: 2px;
    background-color: #4b70e2;
    color: #f9f9f9;
    cursor: pointer;
    box-shadow: 8px 8px 16px #d1d9e6, -8px -8px 16px #f9f9f9;
    &:hover {
      box-shadow: 4px 4px 6px 0 rgb(255 255 255 / 50%),
      -4px -4px 6px 0 rgb(116 125 136 / 50%),
      inset -4px -4px 6px 0 rgb(255 255 255 / 20%),
      inset 4px 4px 6px 0 rgb(0 0 0 / 40%);
    }
  }
}
</style>
