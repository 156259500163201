<!--
  作者：顾先生
-->
<template>
  <div class="move-container">
    <!--  头部-->
    <div class="top">
      <!--    修改-->
      <div class="middle">
        <img src="../../assets/images/top1.png" alt="" class="middle_img"><span class="middle_span">守信企业计划</span>
      </div>
      <!--    修改文字-->
      <div class="top_top_font">
        <span class="top_top_font1">由备案信用服务机构运营 —</span>
        &nbsp;<img src="" class="top_top_img">
        <a href="" @click="One1" class="top_top_font2"><span class="top_top_font2">&nbsp;冀公网备案</span></a>
      </div>
    </div>
    <!-- 您的企业自1988年成立至今，在经营期间 =============== 原：年成立，经营期间-->
    <div class="central">
      <!--  区域1-->
      <div class="top1">
<!--        搜索-->
        <div class="top1_search">
          <van-search
            v-model="searchValue"
            show-action
            placeholder="请输入公司名称"
            @search="onSearch"
          >
            <template #action>
              <van-button size="small" @click="TiaoZhuan" color="linear-gradient(to right, #ff6034, #ee0a24)">搜 索</van-button>
            </template>
          </van-search>
        </div>
        <van-panel>
          <div style="font-size:5.2vw;letter-spacing: 1px">&nbsp;&nbsp;明星企业秀</div>
        </van-panel>
<!--        公司列表-->
        <van-list>
          <van-cell v-for="(item,key) in list" :key="key">
            <div class="PanelBox">
              <div class="PanelBox1">
                <div class="PanelBox1_font1">{{ item.zs_company_name }}</div>
                <div class="PanelBox1_font2">经营状态：正常</div>
              </div>
              <div class="PanelBox2">
                <div class="PanelBox2_font1">加入时间：{{ item.zs_riqi }}</div>
                <van-button @click="chakanxinxi(item.zs_company_name)" size="mini" color="linear-gradient(to right, #ff6034, #ee0a24)" class="btnPanel">
                  查看信息
                </van-button>
              </div>
            </div>
          </van-cell>
        </van-list>
        <div class="botmBtn">
          <van-button @click="xiayiye" type="danger" block color="linear-gradient(to right, #ff6034, #ee0a24)">下一页</van-button>
        </div>
      </div>

    </div>
    <!-- 底部 -->
    <div class="bottom">
      <!-- 底部上面 -->
      <div class="upper">
        <p class="upper_p1">关于中雄征信</p>
        <p class="upper_p2">中雄征信（中雄世纪征信有限公司）是经备案批准的信用服务机构，主要为中小企业提供企业信用服务。</p>
      </div>
      <!-- 底部下面 -->
      <div class="lower">
        <div class="left">
          <a href="" @click="One2"  class="left_p1" id="left_p1"><img src="../../assets/images/bto1.png" alt="">&nbsp;工商登记信息</a>
          <p class="left_p2">联系电话：400 064 2818</p>
        </div>
        <div class="left">
          <a href="" @click="One3"  class="left_p1"><img src="../../assets/images/bto2.png" alt="">&nbsp;&nbsp;冀公网备案</a>
          <p class="left_p2">公众号：守信企业计划</p>
        </div>
      </div>
    </div>
    <!-- 底部文字图片 -->
    <p class="bom_img">
      <img src="../../assets/images/66.png" alt="" id="bom_img">
    </p>
    <van-overlay :show="showIsOk" @click="showIsOk = false">
      <div class="wrapper" @click.stop>
        <van-loading size="24px" vertical color="#ffffff">加载中...</van-loading>
      </div>
    </van-overlay>
    <Vcode :show="HuaIsShow" @success="success" @close="close"></Vcode>
  </div>
</template>

<script>
import axios from 'axios'
import Vcode from 'vue-puzzle-vcode'

export default {
  name: 'Move',
  // 注册组件
  components: { Vcode },
  // 注册方法
  methods: {
    One1 (event) {
      event.preventDefault()
    },
    One2 () {
      event.preventDefault()
    },
    One3 () {
      event.preventDefault()
    },
    onSearch (val) {
      this.certificate(this.val)
    },
    TiaoZhuan () {
      if (this.searchValue === '') {
        // this.$toast.fail('请输入公司名称')
        this.$dialog.alert({
          message: '公司名称不能为空哦~'
        })
        // eslint-disable-next-line no-empty
      } else {
        this.certificate(this.searchValue)
      }
    },
    // 获取公司接口
    Getinformation () {
      axios({
        // 请求方式get或post
        method: 'get',
        // 请求路径
        url: 'https://api.zhongqixin360.com/index.php/Plaque/pageMedia',
        // 请求参数
        params: {
          page: this.pageNum,
          size: 10
        }
      }).then(res => {
        // 成功回调
        this.list = res.data.data.data
      })
    },
    // 点击公司查看信息
    chakanxinxi (GonsiName) {
      this.certificate(GonsiName)
    },
    // 下一页
    xiayiye () {
      this.HuaIsShow = true
    },
    success (msg) {
      this.HuaIsShow = false
      this.pageNum++
      this.Getinformation()
      console.log(msg)
    },
    close () {
      this.HuaIsShow = false
    },
    // 查看证书
    certificate (companyName) {
      this.showIsOk = true
      axios({
        // 请求方式get或post
        method: 'post',
        // 请求路径
        url: 'https://api.zhongqixin360.com/index.php/Plaque/orSsl',
        // 请求参数
        data: {
          title: companyName
        }
      }).then(res => {
        if (res.data.msg === 'success') {
          // 成功回调
          if (res.data.data.zs_class === '20') {
            this.showIsOk = false
            window.location.href = `https://www.zhongqixin360.com/lixinCert/index?v=${res.data.data.zs_id}`
            // window.open(`https://www.zhongqixin360.com/lixinCert/index?v=${res.data.data.zs_id}`)
          } else if (res.data.data.zs_class === '21') {
            this.showIsOk = false
            window.location.href = `https://www.zhongqixin360.com/shouxinCert/index?v=${res.data.data.zs_id}`
            // window.open(`https://www.zhongqixin360.com/shouxinCert/index?v=${res.data.data.zs_id}`)
          } else if (res.data.data.zs_class === '22') {
            this.showIsOk = false
            window.location.href = `https://www.zhongqixin360.com/lxshCert/index?v=${res.data.data.zs_id}`
            // window.open(`https://www.zhongqixin360.com/shouxinCert/index?v=${res.data.data.zs_id}`)
          } else if (res.data.data.zs_class === '23') {
            this.showIsOk = false
            window.location.href = `https://www.zhongqixin360.com/lxqyCert/index?v=${res.data.data.zs_id}`
            // window.open(`https://www.zhongqixin360.com/shouxinCert/index?v=${res.data.data.zs_id}`)
          } else if (res.data.data.zs_class === '25') {
            this.showIsOk = false
            window.location.href = `https://www.zhongqixin360.com/sxcnCert/index?v=${res.data.data.zs_id}`
            // window.open(`https://www.zhongqixin360.com/shouxinCert/index?v=${res.data.data.zs_id}`)
          } else if (res.data.data.zs_class === '26') {
            this.showIsOk = false
            window.location.href = `https://www.zhongqixin360.com/storeCert/index?v=${res.data.data.zs_id}`
            // window.open(`https://www.zhongqixin360.com/shouxinCert/index?v=${res.data.data.zs_id}`)
          } else if (res.data.data.zs_class === '27') {
            this.showIsOk = false
            window.location.href = `https://www.zhongqixin360.com/sxcnjy/index?v=${res.data.data.zs_id}`
            // window.open(`https://www.zhongqixin360.com/shouxinCert/index?v=${res.data.data.zs_id}`)
          } else if (res.data.data.zs_class === '28') {
            window.location.href = `https://www.zhongqixin360.com/sxcnjysh/index?v=${res.data.data.zs_id}`
          } else if (res.data.data.zs_class === '29') {
            window.location.href = `https://www.zhongqixin360.com/cncxjyzCert/index?v=${res.data.data.zs_id}`
          } else if (res.data.data.zs_class === '30') {
            window.location.href = `https://www.zhongqixin360.com/cncxjyzCert/index?v=${res.data.data.zs_id}`
          } else if (res.data.data.zs_class === '31') {
            window.location.href = `https://www.zhongqixin360.com/cncxjyzCert/index?v=${res.data.data.zs_id}`
          }
        } else {
          this.showIsOk = false
          this.$toast.fail(res.data.msg)
        }
      }).catch(err => {
        this.showIsOk = false
        this.$toast.fail(err)
      })
    }
  },
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {
      HuaIsShow: false,
      searchValue: '',
      list: [],
      // 页数
      pageNum: 1,
      // 遮罩层
      showIsOk: false
    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {
    this.Getinformation()
  },
  // 页面内容渲染完成
  mounted () {},
  beforeCreate () {
    document
      .querySelector('body')
      .setAttribute('style', 'background-color:#d80303')
  }
}
</script>

<style scoped lang="less">
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
.van-search__action{
  font-size:3.9vw;
  line-height:0vw;
}
.van-panel__footer{
  margin-left:20vw;
}
.PanelBox{
  line-height:7vw;
  background-color: #f4f4f4;
  border-radius: 1.5vw;
  padding: 1.5vw;
  .PanelBox1{
    .PanelBox1_font1{
      font-size: 4vw;
    }
    .PanelBox1_font2{
      font-size: 4vw;
      color: #d80303;
    }
  }
  .PanelBox2{
    display: flex;
    justify-content: space-between;
    font-size: 3.5vw;
  }
}
.van-cell{
  padding:2vw 2vw;
}
.btnPanel{
  width:70px;
  cursor: pointer;
}
</style>
<style scoped src="../../style/success.css"></style>
